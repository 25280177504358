import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import React, { useState } from "react";
import Alert from 'react-bootstrap/Alert'
import Carousel from 'nuka-carousel';

function CarouselMainComponent() {
    return (
        <>
            <Carousel wrapAround={true} slidesToShow={6} autoplay={true} adaptiveHeight={true} cellSpacing={1} withoutControls={true}>
                <img alt="Bridgewater" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-Bridgewater-C-2.jpg" />
                <img alt="Brock" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-Brock-U-2.jpg" />
                <img alt="Clemson" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-Clemson-U-2.jpg" />
                <img alt="Fordham" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-Fordham-U-2.jpg" />

                <img alt="METU" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-METU-2.jpg" />
                <img alt="Noble-and-Greenough" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-Noble-and-Greenough-2.jpg" />
                <img alt="Okanagan" height="185px" width="185px"src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-Okanagan-C-2.jpg" />
                <img alt="Old-Dominion" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-Old-Dominion-U-2.jpg" />

                <img alt="Pomona" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-Pomona-C-2.jpg" />
                <img alt="South-Dakota-SMT" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-South-Dakota-SMT-2.jpg" />
                <img alt="Trent" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-Trent-U-2.jpg" />
                <img alt="Calgary" height="185px" width="185px"src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-U-Calgary-2.jpg" />
                <img alt="Ottawa" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-U-Ottawa-2.jpg" />
                <img alt="Toronto"  height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-U-Toronto-3.jpg" />
                <img alt="Toronto"  height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-U-Toronto-Mississauga-2.jpg" />
                <img alt="Boulder"  height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-UC-Boulder-2.jpg" />
                <img alt="Calgary"  height="185px" width="185px"src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-U-Calgary-2.jpg" />
                <img alt="Wilfred-Laurier" height="185px" width="185px" src="https://storage.googleapis.com/sb-test-storage/school_logos/Logo-Wilfred-Laurier-U-3.jpg" />
            </Carousel>
        </>
    )

}


export default CarouselMainComponent;