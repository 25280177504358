export async function syncGrades(){

    // const endPoint = "https://sb-lti-service-ycgnbf2s4a-uc.a.run.app" + "/sb-lti-v-1-1/syncGrades";process.env.LTI_SERVICE 
    const endPoint = process.env.REACT_APP_LTI_SERVICE + "/sb-lti-v-1-1/syncGrades";

    // const endPoint = "http://localhost:8080"+ "/sb-lti-v-1-1/syncGrades";

    const options = {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'sb-application-name': 'sb-site-ui',
            'sb-application-key': 'jmd-test',
            'Access-Control-Allow-Origin': '*',
            'sessionID': sessionStorage.getItem("sessionID"),
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }),
        mode: 'cors',
        credentials: 'omit',
        // body: JSON.stringify(theBody)
        // body: JSON.stringify(theBody)
    };

    try{
        await fetch(endPoint, options);
    }catch(err){
        console.log(err);
    }
    

}

export async function forceSyncGrades(studentId, ltiKey){
    const endPoint = process.env.REACT_APP_LTI_SERVICE + "/forceSyncById/" + sessionStorage.getItem("sessionID") + "/old.dominion.u.c.steel/" + studentId;

    const options = {
        method: 'GET',
        headers: new Headers({
            'Content-Type': 'application/json',
            'sb-application-name': 'sb-site-ui',
            'sb-application-key': 'jmd-test',
            'Access-Control-Allow-Origin': '*',
            'sessionID': sessionStorage.getItem("sessionID"),
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Content-Type, sb-application-name, sb-application-key, sessionID ',
        }),
        mode: 'cors',
        credentials: 'omit',
    };

    try{
        // await fetch(endPoint, options);
        let response = await fetch(endPoint, options);
        let forceSyncResults = await response.json();

        return {
            ERROR: false,
            details: forceSyncResults
        };
    }catch(err){
        console.log(err);
        return {
            ERROR: true,
            details: JSON.stringify(err)
        };
    }
    

}