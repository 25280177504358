import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import CarouselMainComponent from "../components/carousel/carouselMainComponent";
import LoginComponent from "../components/login-registration/loginComponent";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import ProductCallout from "./productCallout";
import Card from "./card";
import MissionImage from "../assets/images/cell.png"
import CellImage from "../assets/images/cell_2.png"
import EColiImage from "../assets/images/Ecoli-Transparent-1.png"
import AcademyImage from "../assets/images/AcademyIcon.png"
import ClassroomImage from "../assets/images/ClassroomIcon.png"
import EnterpriseImage from "../assets/images/EnterpriseIcon.png"
import SleekButton from "./sleekButton";
import ModelViewbox from "./modelViewbox";
import ParalaxVideo from "./paralaxVideo";
import BannerCarousel from "./carousel/bannerCarousel";

const queryParams = new URLSearchParams(window.location.search);

function Body() {
	const [isMobile, setIsMobile] = useState(false);
	const [invalidLogin, setInvalidLogin] = useState(false);
	const [loginModalShow, setLoginModalShow] = useState(false);
	const [filterModalShow, setFilterModalShow] = useState(false);
	const [offsetY, setOffsetY] = useState(0);
	const handleScroll = () => setOffsetY(window.screenY);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		setInvalidLogin(queryParams.get("invalidLogin"));
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		if (queryParams.get("invalidLogin")) {
			setLoginModalShow(true);
		}

		handleResize(); // Check initial screen width
		window.addEventListener("resize", handleResize); // Update on resize

		return () => {
			window.removeEventListener("scroll", handleScroll);
			window.removeEventListener("resize", handleResize); // Cleanup
		};
	}, []);

	function getValuesBox() {
		const cards =[
			<Card
				image={EColiImage}
				title={"A Continuous Journey"}
				backgroundColor={"white"}
				description={
					"All of biology is connected, from atoms, to cells, to evolution, to ecology, and we therefore teach it as such."
				}
				index={0}
				imgAlt={"A 3D rendering of a bacterium, representing the foundational level of life in the continuous journey of biology."}
			/>,
			<Card 
				image={MissionImage}
				backgroundColor={"#131619"}
				color="white"
				index={1}
				title={"Don't Memorize, Understand"}
				description={"Concepts explained in a way that allows students to truly	understand how life works."}
				imgAlt={"A 3D model of a complex molecule, symbolizing the deeper understanding of biological processes beyond rote memorization."}
				/>,
				
				<Card 
				image={CellImage}
				backgroundColor={"#054bb4"}
				color={"white"}
				index={2}
				title={"Everything We Know Today"}
				description={"	Learn with scientifically accurate visualizations representative of everything we know today."}
				imgAlt={"A scientifically accurate visualization of a virus, showcasing the cutting-edge knowledge incorporated into the learning materials."}				/>,
		]

		return (<div className="grid-container">
		{cards.map((item, index) => (
		  <div key={index}>
			{item}
		  </div>
		))}
	  </div>)
	}

	return (
		<div><Modal
		show={loginModalShow}
		onHide={() => setLoginModalShow(false)}
		size="lg"
		aria-labelledby="contained-modal-title-vcenter"
		centered
	>
		<Alert align="center" key="warning" variant="warning">
			Invalid Session, please login or re-enter through your school portal.
		</Alert>
		<LoginComponent />
	</Modal><div style={{ position: "relative", "min-height": "calc(100vh - 150px)" }}>
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1"
			></meta>
			<div
				style={{
					padding: "4%",
					paddingRight: "40px",
					paddingBottom: "50px",
					paddingTop: "100px",
				}}
			>
				<Row>
					<Col>
						<div align="left">
							<title style={{display: "block", 'font-weight': "bold", 'font-size': "54px"}}>BIOLOGY TEXTBOOKS</title>
							<h1
								style={{ fontWeight: "bold", fontSize: 54, color: "#054bb4" }}
							>
								RE-IMAGINED
							</h1>
							<p>A Visual Approach to Biology Education</p>
						</div>
					</Col>
					<Col align="left">
						<p align="left">
							We integrate data from across the literature to produce accurate,
							3D Animated Textbooks representative of everything we know today
							so students can truly understand.{" "}
						</p>
						<SleekButton link={"#filter"}></SleekButton>
					</Col>
				</Row>
			</div>
			<div className="image-container">
				<ParalaxVideo />
			</div>
			<div
				style={
					isMobile
						? {
								display: "block",
								textAlign: "center",
								backgroundColor: "#f6f7f8",
								paddingBottom: "30px",
						  }
						: {
								textAlign: "left",
								backgroundColor: "#f6f7f8",
								paddingBottom: "30px",
						  }
				}
			>
				<Container>
					<h1
						align={!isMobile ? "left" : "center"}
						style={{ padding: "4%", fontSize: "54px", color: "#5a5a5a" }}
					>
						Ignite Your Potential.
					</h1>
					<Row>
						{!isMobile ? (
							<Col>
								<ModelViewbox src={"https://storage.googleapis.com/interactive-structures/protein-viewer/index_hp_final.html?model=objects/ATP-Synthase-10&scale=0.19&colors=[9B9EFE,018502,C1A37D,0001FB,8B0486,6262F8,FEFF85,056802,FF6201,FEC702,00BE00,ffffff]"}/>
							</Col>
						) : (
							<></>
						)}
						<Col>
							<h2 style={{ fontWeight: "bold" }}>The Smart Biology Way</h2>
							<br></br>
							<Row>
								<div className="grid-container">
									<div
										className="story-card"
										style={{
											padding: "20px",
											borderRadius: "20px",
											backgroundColor: "white",
										}}
									>
										<div>
											<h3
												style={{
													fontWeight: "bold",
													fontSize: 42,
													textAlign: "left",
													color: "#054bb4",
												}}
											>
												Your Choice
											</h3>
											<p>
												Instructor customization for Classroom and Student
												choices for Academy.
											</p>
										</div>
									</div>
									<div style={{ gridRow: "span 2 / auto" }}>
										<div
											className="story-card"
											style={{
												padding: "20px",
												borderRadius: "20px",
												backgroundColor: "white",
											}}
										>
											<div>
												<h3
													style={{
														fontWeight: "bold",
														fontSize: 42,
														textAlign: "left",
														color: "#054bb4",
													}}
												>
													Unique Approach
												</h3>
												<p>
													We integrate data to show students both the fine
													details and the big picture, with continuity between
													disciplines.
												</p>
											</div>
										</div>
									</div>
									<div
										className="story-card"
										style={{
											padding: "20px",
											borderRadius: "20px",
											backgroundColor: "white",
										}}
									>
										<div>
											<h3
												style={{
													fontWeight: "bold",
													fontSize: 42,
													textAlign: "left",
													color: "#054bb4",
												}}
											>
												Class Tools
											</h3>
											<p>
												LMS integration, instructor resources, online notes,
												quizzes, and more.
											</p>
										</div>
									</div>
								</div>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
			<br /> <br />
			<Container>
				<Row>
					<h2
						id="filter"
						style={{ padding: "4%", fontSize: "42px", color: "#5a5a5a" }}
					>
						Extraordinary, 3D Animated Biology Courses for <u>All</u> learners
						<br />
						Don’t just memorize, Understand.
					</h2>
				</Row>

				<br />

				<div className="grid-container">
					<ProductCallout
						isMobile={isMobile}
						image={ClassroomImage}
						title={"Classroom"}
						description={
							"For students in a class using Smart Biology, or for Instructors looking to adopt."
						}
						// link={"/subjects"}
						onClick={() => {
							setFilterModalShow(true);
						}}
						linkText={"Explore"}
					/>

					<ProductCallout
						isMobile={isMobile}
						image={AcademyImage}
						title={"Academy"}
						description={
							"For individuals looking to use Smart Biology for self-study or presentation."
						}
						link={"https://smart-biology-academy.getlearnworlds.com/courses"}

						linkText={"Start"}
					/>
					<Modal
						show={filterModalShow}
						onHide={() => setFilterModalShow(false)}
						size="lg"
						aria-labelledby="contained-modal-title-vcenter"
						centered
					>
						<div style={{padding: '24px', textAlign: "center" }}>
							<p>For students in a class using Smart Biology, or for Instructors looking to adopt.</p>
							<h3 style={{ }}>I'm a classroom:</h3>
							<br></br>
							<div
								style={{
									display: "flex",
									textAlign: "center",
									justifyContent: "center",
								}}
							>
								<a href="https://www.smart-biology.com/buy/class">
									<Button
										size="lg"
										style={{ marginRight: "20px", marginLeft: "20px" }}
									>
										Student
									</Button>
								</a>
								<a href="https://www.smart-biology.com/subjects">
									<Button
										size="lg"
										style={{ marginRight: "20px", marginLeft: "20px" }}
									>
										Educator
									</Button>
								</a>
							</div>
						</div>
					</Modal>

					<ProductCallout
						isMobile={isMobile}
						image={EnterpriseImage}
						title={"Enterprise"}
						description={
							"For libraries, industry, or businesses looking to license our content for organizational use."
						}
						link={"/contact-us?type=enterprise"}
						linkText={"Quote"}
					/>
				</div>
				<br />
				<br />
			</Container>
			<div
				style={{
					backgroundColor: "#f6f7f8",
					paddingBottom: "100px",
				}}
			>
				<Container>
					<h2
						align="left"
						style={{
							padding: "4%",
							paddingBottom: "50px",
							fontSize: "42px",
							color: "#5a5a5a",
						}}
					>
						Our Values.
					</h2>

					{getValuesBox()}
				</Container>
			</div>
			<BannerCarousel></BannerCarousel>
			<Container>
				<div style={{ color: "#000080" }}>
					<br />
					<h2 style={{ padding: "4%", fontSize: "42px", color: "#5a5a5a" }}>
						Used in Classrooms across North America and Beyond
					</h2>
				</div>
				<CarouselMainComponent />
				<br />
				<br />
			</Container>
		</div>
		</div>
	);



}

export default Body;
