import { Container } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect, useRef } from "react";
import Alert from 'react-bootstrap/Alert';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton
} from 'video-react';
import addToCart from '../../services/bffService';
import { addTax } from '../../services/bffServices/updateShoppingCart';
import { submitPO } from '../../services/bffServices/submitOrder';
import Cookies from 'universal-cookie';
import { duplicateUserCheckByEmail } from '../../services/bffServices/getUserDetails';
import Spinner from 'react-bootstrap/Spinner';
import bcrypt from '../../../node_modules/bcryptjs';
import Footer from '../footer';
import { updatePageTitle } from '../../utls/lib';

const saltRounds = 10;

const cookies = new Cookies();
const queryParams = new URLSearchParams(window.location.search);


export default function TrialMainPageMainComponent(props) {

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [userType, setUserType] = useState("N/A");
    const [email, setemail] = useState("");
    const [password, setPassword] = useState("");
    const [school, setSchool] = useState("");
    const [country, setCountry] = useState("");
    const [province, setProvince] = useState("");
    const [contentPreferance, setContentPreferance] = useState("");
    const [foundUs, setFoundUs] = useState("");
    const [message, setMessage] = useState("");
    const [formValidationMsg, setFormValidationMsg] = useState("");
    const [foundUsOther, setFoundUsOther] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [buttonTextSubmit, setButtonTextSubmit] = useState("Submit");
    const [enableCourseDetails, setEnableCourseDetails] = useState(false);
    const [classCourseName, setClassCourseName] = useState("");
    const [numStudents, setNumStudents] = useState("");
    const [selectedValue, setSelectedValue] = useState(""); // Initial state is empty string
    const [whoAmI, setWhoAmI] = useState("");





    const addOtherField = async (event) => {
        if (event != "Other") {
            setFoundUs(event);
            setFoundUsOther("");
        }

        if (event == "Other") {
            setFoundUsOther("Other");
        }
    }

    const createTrialAccount = async () => {
        setDisableSubmit(true);

        setButtonTextSubmit(() => {
            return (
                <div>
                    <Spinner animation="border" size="sm" />
                </div>
            );
        })
        setFormValidationMsg("");

        var whoAmICheck = false;
        if (whoAmI === "Professor / Instructor")
        {
            if(classCourseName != "" && numStudents != ""){
                whoAmICheck = true;
            }
        }else{
            whoAmICheck = true;
        }

        const trialInfoSubmitted_test = {
            "firstname": firstname,
            "lastname": lastname,
            "email": email,
            "userType": userType,
            "school": school,
            "country": country,
            "province": province,
            "whoAmI": whoAmI,
            "classCourseName": classCourseName,
            "numStudents": numStudents,
            "foundUs": foundUs,
            "message": message
        }
        // console.log("trialInfoSubmitted_test" + JSON.stringify(trialInfoSubmitted_test));

        if (email != "" && firstname != "" && lastname != "" && school != "" && country != ""  && message != "" && province != "" && foundUs != "" && whoAmI != "" && whoAmICheck == true) {

            try {

                const trialInfoSubmitted = {
                    "firstname": firstname,
                    "lastname": lastname,
                    "email": email,
                    "userType": userType,
                    "school": school,
                    "country": country,
                    "province": province,
                    "whoAmI": whoAmI,
                    "classCourseName": classCourseName,
                    "numStudents": numStudents,
                    "foundUs": foundUs,
                    "message": message
                }

                var duplicateCheck = await duplicateUserCheckByEmail(email);

                if (!duplicateCheck) {
                    await addToCart("62e72a061b00ad1172992fe8", true)

                    await bcrypt.hash(password, saltRounds, async function (err, hash) {

                        var payload = {
                            "shoppingCartId": cookies.get('sb-sc'),
                            "existingUser": false,
                            "fName": firstname,
                            "lName": lastname,
                            "userName": email,
                            "password": hash,
                            "email": email,
                            "addressInfo": {
                                "country": country,
                                "province": province,
                                "postalCode": "N/A"
                            }

                        }

                        setFormValidationMsg(() => {
                            return (
                                <>
                                    <Alert key="success" variant="success">
                                        Trial Membership has been created, you should see an email shortly.
                                    </Alert>
                                </>
                            )
                        })

                        setButtonTextSubmit("Submitted");

                        const taxPayload = {
                            "province": province,
                            "scId": cookies.get('sb-sc'),
                            "tax": {
                                "hst": .013
                            }
                        }
                        await addTax(taxPayload);

                        const results = await submitPO(payload, true, trialInfoSubmitted);
                        cookies.remove('sb-sc');
                        cookies.set('sc-orderId', results.id, { path: '/' });
                    });


                } else {
                    setDisableSubmit(false);

                    setButtonTextSubmit("Submit")
                    setFormValidationMsg(() => {
                        return (
                            <>
                                <Alert key="danger" variant="danger">
                                    Email already exists, please use another or log in to access your account.
                                </Alert>
                            </>
                        )
                    })
                }
            } catch (e) {
                setDisableSubmit(false);

                setButtonTextSubmit("Submit")
                setFormValidationMsg(() => {
                    return (
                        <>
                            <Alert key="danger" variant="danger">
                                An Error Occured, Please Try Again
                            </Alert>
                        </>
                    )
                })
            }
        } else {
            setDisableSubmit(false);

            setButtonTextSubmit("Submit")
            setFormValidationMsg(() => {
                return (
                    <>
                        <Alert key="danger" variant="danger">
                            Please ensure all mandatory fields are entered
                        </Alert>
                    </>
                )
            })
        }
    }

    useEffect(async () => {
        const allowedTypes = ["icb", "enterprise"]; // Add all allowed types here

        updatePageTitle("Request a Trial");
        if (queryParams.has('type')) {
            const typeParam = queryParams.get('type').toLowerCase(); // Convert to lower case for case-insensitive comparison

            if (allowedTypes.includes(typeParam)) {
                // If the type is in the allowed list, set the user type
                setUserType(typeParam.toUpperCase());
            } else {
                // Handle invalid type parameter
                console.warn("Invalid 'type' parameter in URL, userType not applied");
                // Optionally, redirect to a safe page or show an error message
            }
        }

    }, [enableCourseDetails]);

    return (
        <div style={{ 'background-color': "#f8f9fa" }}>
            <Container style={{ padding: "10px 0px 50px 0px", 'background-color': "#f8f9fa" }}>
                <Row >
                    <Alert style={{ textAlign: "left", 'font-size': "20px", }} variant="warning"><strong>Important: </strong>Are you a student sent here by your instructor?<Button style={{ marginLeft: '10px' }} size="sm" variant="success" href="/buy/class">Search My Class</Button></Alert>
                </Row>
                <Card style={{ padding: "20px", 'background-color': "#f8f9fa", border: "0px" }}>
                    <Row>
                        <Col align="left">
                            <Container>
                                <div align="center">
                                    <h4>
                                        Why animated, interactive textbooks?
                                    </h4>
                                </div>
                                <div>
                                    <small>
                                        Our Animated Textbooks represent a highly visual approach to understanding biology and a long overdue alternative to the traditional textbook. We want students to not just memorize, but truly understand biology.
                                        <br /><br />
                                        We therefore integrate data from across the literature to create a comprehensive biology courseware solution containing high quality, accurate animations, 3D interactive structures, in-lesson quizzes, text, figures, and much more, all representative of everything that we know today on a given subject..
                                        <br /><br />
                                        Students can now see, hear, and interact with atoms, genes, cells, evolution, and more.
                                    </small> <br /><br />
                                    <Player
                                        playsInline
                                        poster="https://storage.googleapis.com/sb-general/wp-content/uploads/2021/03/SB-Instructor-Resources-3.jpg"
                                        src="https://storage.googleapis.com/sb-general/Smart-Biology-90-Sec-Explainer-Video-Bit-30vs.mp4"
                                    >
                                        <BigPlayButton position="center" />

                                        <ControlBar>
                                            <ReplayControl seconds={10} order={1.1} />
                                            <ForwardControl seconds={30} order={1.2} />
                                            <CurrentTimeDisplay order={4.1} />
                                            <TimeDivider order={4.2} />
                                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                                            <VolumeMenuButton disabled />
                                        </ControlBar>
                                    </Player>
                                </div><br />
                            </Container>
                        </Col>
                        <Col style={{ align: "left" }} sm={5}>
                            <Card style={{ align: "left" }}>
                                <Card.Body style={{ align: "left" }}>
                                    <Card.Header>
                                        <h4>Request Instructor Trial</h4>
                                    </Card.Header><br />
                                    <Card.Text align="left">
                                        Just fill in the form below and we’ll get you started.  Once we have recieved your submission, we’ll create your account to get you started!
                                    </Card.Text>
                                    <Form style={{ align: "left" }}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Row style={{ padding: "5px" }}>
                                                <Col>
                                                    <Form.Control onChange={event => setFirstname(event.target.value)} type="text" autoComplete="text" placeholder="First Name*" />
                                                </Col>
                                                <Col>
                                                    <Form.Control onChange={event => setLastname(event.target.value)} type="text" autoComplete="text" placeholder="Last Name*" />
                                                </Col>
                                            </Row>
                                            <Row style={{ padding: "5px" }}>
                                                <Col>
                                                    <Form.Control autoComplete='email' onChange={event => setemail(event.target.value)} type="email" placeholder="Email*" />
                                                    <div align="left" style={{ padding: "0px 0px 0px 10px", 'color': "grey" }}><small>We will never share your email address</small></div>
                                                </Col>
                                            </Row>
                                            <Row style={{ padding: "5px" }}>
                                                <Col>
                                                    <Form.Control onChange={event => setPassword(event.target.value)} type="password" autoComplete="password" placeholder="Password*" />
                                                </Col>
                                            </Row>
                                            <Row style={{ padding: "5px" }}>
                                                <Col>
                                                    <Form.Control onChange={event => setSchool(event.target.value)} type="text" autoComplete="text" placeholder="School*" />
                                                </Col>
                                            </Row>
                                            <Row style={{ padding: "5px" }}>
                                                <Col>
                                                    <Form.Control onChange={event => setCountry(event.target.value)} type="text" autoComplete="text" placeholder="Country*" />
                                                </Col>
                                            </Row>
                                            <Row style={{ padding: "5px" }}>
                                                <Col>
                                                    <Form.Control onChange={event => setProvince(event.target.value)} type="text" autoComplete="text" placeholder="Province/State*" />
                                                </Col>
                                            </Row>
                                            <Row style={{ padding: "5px" }}>
                                                <Col>
                                                    <Card style={{ height: "100%", padding: "5px" }}>
                                                        <small>
                                                            <Form.Group as={Row} className="mb-3" align="left" style={{ padding: "0px 0px 0px 20px" }}>
                                                                <Form.Label align="left">
                                                                    Which of the following best describes you?*
                                                                </Form.Label>
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Professor / Instructor"
                                                                    name="accessSelection"
                                                                    id="prof"
                                                                    align="left"
                                                                    onChange={(event) => {
                                                                        if (event.target.checked) {
                                                                          setSelectedValue(event.target.id);
                                                                          setWhoAmI("Professor / Instructor")
                                                                        } else {
                                                                          setSelectedValue(""); // Set back to empty string if deselected
                                                                        }
                                                                      }}
                                                                />
                                                                {selectedValue === "prof" &&
                                                                    <Row style={{ padding: "5px" }}>
                                                                        <Col>
                                                                            <Form.Control onChange={event => setClassCourseName(event.target.value)} type="text" autoComplete="text" placeholder="Course/Class" />
                                                                        </Col>
                                                                        <Col>
                                                                            <Form.Control onChange={event => setNumStudents(event.target.value)} type="text" autoComplete="text" placeholder="# of Students" />
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Student"
                                                                    name="accessSelection"
                                                                    id="ebook"
                                                                    onChange={(event) => {
                                                                        if (event.target.checked) {
                                                                          setSelectedValue(event.target.id);
                                                                          setWhoAmI("Student")
                                                                        } else {
                                                                          setSelectedValue(""); // Set back to empty string if deselected
                                                                        }
                                                                      }}
                                                                    
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Industrial Professional / Leader"
                                                                    name="accessSelection"
                                                                    id="web-ebook"
                                                                    onChange={(event) => {
                                                                        if (event.target.checked) {
                                                                          setSelectedValue(event.target.id);
                                                                          setWhoAmI("Industrial Professional / Leader")
                                                                        } else {
                                                                          setSelectedValue(""); // Set back to empty string if deselected
                                                                        }
                                                                      }}
                                                                />
                                                            </Form.Group>
                                                        </small>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row style={{ padding: "5px" }}>
                                                <Col>
                                                    <Form.Control onChange={event => setMessage(event.target.value)} as="textarea" rows={5} placeholder="Message" />
                                                </Col>
                                            </Row>
                                            <Row style={{ padding: "5px" }}>
                                                <Col>
                                                    <Form.Select size="sm" name="foundUs" aria-label="Default select example" onChange={event => addOtherField(event.target.value)}>
                                                        <option>How did you hear about us?*</option>
                                                        <option value="Instagram">Instagram</option>
                                                        <option value="Twitter">Twitter</option>
                                                        <option value="LinkedIn">LinkedIn</option>
                                                        <option value="YouTube">YouTube</option>
                                                        <option value="Google">Google</option>
                                                        <option value="Other">Other</option>
                                                    </Form.Select>
                                                    {foundUsOther == "Other" &&
                                                        <>
                                                            <Row style={{ padding: "5px 0px 5px 0px" }}>
                                                                <Col>
                                                                    <Form.Control onChange={event => setFoundUs(event.target.value)} type="text" autoComplete="text" placeholder="Other*" />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }

                                                </Col>
                                            </Row>

                                        </Form.Group>
                                        <div className="d-grid gap-2">
                                            <div align="left" color="red">{formValidationMsg}</div>
                                            <Button onClick={() => createTrialAccount()} disabled={disableSubmit}>{buttonTextSubmit}</Button>
                                            <Button variant="secondary" type="reset">Reset Form</Button>
                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </Container>
            {/* <Footer /> */}
        </div>
    );
}