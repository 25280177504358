import React, { useRef, useEffect } from "react";
import { useState } from "react";
import video from "../assets/videos/SmartBiology-Education-Animation-Cell-Looping-Video-Bit-30-VF.mp4"
import poster from "../assets/images/unloaded-image.jpg"

function ParalaxVideo() {
	const [offsetY, setOffsetY] = useState(0);
	const handleScroll = () => setOffsetY(window.scrollY);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
        var video = document.getElementById("myVideo");

        video.playbackRate = 0.75;
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

    

	return (
		<section>
			<div>
				<video
                    id="myVideo"
                    poster={poster}
                    autoPlay loop muted playsInline
					style={{
						width: "110%",
						height: "600px",
						objectFit: "cover",
						transform: `translateY(${offsetY * 0.2}px)`,
						position: "center",
					}}
					alt="A mesmerizing 3D animation showcasing the intricate dance of 
					cellular structures within a eukaryotic cell. Vibrant colors highlight the 
					endoplasmic reticulum (green), mitochondria (yellow), and other organelles, 
					revealing the complex beauty of life at the microscopic level."
				>
					<source src={video} type="video/mp4" />
					<track src="descript.vvt" kind="captions" srclang="en" label="captions"></track>
                    Your browser does not support the video tag.
				</video>
				{/* <img src={}
                style={{width: "110%",
						height: "600px",
						objectFit: "cover", transform: `translateY(${offsetY * 0.2}px)`,
                        position: 'center'}}></img> */}
			</div>
		</section>
	);
}

export default ParalaxVideo;
