import Accordion from 'react-bootstrap/Accordion'
import UpdateIpListDetailsComponent from "./updateIpList";
import CreateIpListDetailsComponent from "../../create-ip-list-details";


function IpListDetailsMainComponent() {
    return (
        <>
<div align="left">
    <h3>Select Tool from the below Options</h3>
    {/* <hr style={{ 
        width: '30%', // Adjust width as needed
        marginLeft: '35%', // Adjust left margin for centering
        marginTop: '-20px', // Adjust top margin for closeness
        marginBottom: '30px' // Add a bit of bottom margin for spacing
    }} />  */}
</div>

            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>1. Create IP Access list</Accordion.Header>
                    <Accordion.Body>
                        <CreateIpListDetailsComponent />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>2. Update IP Access list</Accordion.Header>
                    <Accordion.Body>
                        <UpdateIpListDetailsComponent />
                    </Accordion.Body>

                </Accordion.Item>
            </Accordion>


        </>
    )
}

export default IpListDetailsMainComponent;
