import React from 'react';
import { Col, Row } from 'react-bootstrap';


const Card = ({ backgroundColor, image, title, link, description, index, color, subtitle, imgAlt}) => {
  return (
   <div className='story-card' style={{background: backgroundColor, display: 'block'}}>
      <div style={{ margin: '30px'}}>
       {index != null ?  <h3	style={{ marginTop: '10px', fontSize: "20px", textAlign: 'left', color: color ?? '#595959'}}>{`0${index + 1}`}</h3>:<></>}
        <h3 align= 'left' style={{color: color}}>{title}</h3>
        {subtitle != null ? <p align= 'left' style={{color: color}}>{subtitle}</p> : <></>}
        <img src={image} style={{width: '50%', margin: '10px'}} alt={imgAlt}/>
        <p align='left' style={{color: color}}>{description}</p>
      </div>
   </div>
  );
  
};

export default Card;
