import ProgressBar from 'react-bootstrap/ProgressBar'
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { getTextbookList, getTextbookListV2 } from '../../services/bffServices/textbookBFFServices/getTextbooks';
import { getUnitByID } from '../../services/bffServices/textbookBFFServices/getUnitDetails';
import React, { useState, useEffect } from "react";
import { getUserProgressSummary } from '../../services/bffServices/getUserDetails';
import Spinner from 'react-bootstrap/Spinner'
import { updateUserProgress } from '../../services/bffServices/textbookBFFServices/updateUserProgress';
import { getExistingSession } from '../../services/bffServices/getSessionInfo';
import { calculateGradeByUnit } from '../../utls/lib';
const url = require('url');
const domainURL = "https://storage.googleapis.com/sb-general" //https://storage.googleapis.com/sb-general/

function CurrentCourseList(payload) {
	const language = sessionStorage.getItem("lang") || "EN"
	const [textbook, setTextbook] = useState();
	const [unitId, setUnitId] = useState("");
	const [unit, setUnit] = useState();
	const [unitTitle, setUnitTitle] = useState();
	const [unitCoverImage, setUnitCoverImage] = useState();
	const [courseProgress, setCourseProgress] = useState("");
	const [courseComplete, setCourseComplete] = useState();
	const [courseTotal, setCourseTotal] = useState();
	const [continueButton, setContinueButton] = useState("");
	const [unitGrade, setUnitGrade] = useState(0);
	const [isBusy, setBusy] = useState(true);
	const [textbookListv2, setTextbookListv2] = useState(() => {
		return (
			<>
				<Spinner animation="border" variant="success" /><br />
				<div>
					<Alert variant="success">
						<Alert.Heading>Hey, nice to see you</Alert.Heading>
						<p>
							Getting Course List, if you are a new user it could take 5 mins to have the account fully created
						</p>
						<hr />
						<p className="mb-0">
							If your course has not appeared, please reload the page, or email support@smart-biology.com for assistance.
						</p>
					</Alert>
				</div>
			</>
		)
	});

	//lang
	const [unitTotalGrade, setUnitTotalGrade] = useState("Unit Total Grade");
	const [lessonCompleted, setLessonsCompleted] = useState("lessons completed");
	const [numLessons, setNumLessons] = useState("Lessons");
	const [finalGrades, setFinalGrades] = useState([]);



	const textbookList = async () => {
		var textbookListResponse = await getTextbookList()
			.then(payload => getUnitByID(payload.id))//need to add ability to ready multiple units from text book
			.then(payload => {
				setTextbook(textbookListResponse);
				setUnitId(payload.id);
				setUnit(payload);
				if (language == "FR") {
					setUnitTitle(payload?.title_fr)
				}
				if (language == "EN") {
					setUnitTitle(payload?.title_en)
				}
				setUnitCoverImage(payload?.unitCoverImage_en)
				setUnitId(payload.id)
			});
	}

	const textbookList_V2 = async () => {
		var textbookListResponseV2 = await getTextbookListV2()
		const userSession = await getExistingSession();
		var finalGradesResponse = await calculateGradeByUnit();
		console.log("finalGradesResponse" + JSON.stringify(finalGradesResponse))

		var multiSessonUI = userSession.payload.multiSession || false;
		var coverWidth = "80%";

		if (multiSessonUI) {
			coverWidth = "100%"
		}

		// console.log("finalGrades" + JSON.stringify(finalGrades));
		return (
			<>
				<div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
					{textbookListResponseV2.map( (unit) => {

					
						var parsedURL = url.parse(unit.unitCoverImage_en);
						var updatedURL = domainURL + parsedURL.pathname;
						var altText= "Cover Image for " + unit.title_en;
						return (
							<>
								<div style={{ display: "flex", alignItems: "left" }}>
									<div style={{ width: "25%" }}>
										<img
											style={{ verticalAlign: "top", padding: "5px" }}
											width={coverWidth}
											src={updatedURL || "op"}
											loading="lazy"
											alt={altText}
										/>
									</div>
									<div style={{ width: "75%" }}>
										<div>
											<h2 style={{ fontSize: "20px", textAlign: "left", margin: "10px" }}>{unit.title_en}</h2>
										</div>
										{multiSessonUI === true && (
											<div style={{ fontSize: "12px", textAlign: "left", margin: "10px" }}>
												<ReactMarkdown rehypePlugins={[rehypeRaw]}>
													{unit.content_en}
												</ReactMarkdown>
											</div>
										)}
										{multiSessonUI !== true && (
											<>
												<div>
													<hr style={{ width: "100%" }} />
													<div style={{ textAlign: "left" }}>
														<h3 style={{ fontSize: "16px" }}>Unit Progress:</h3>
													</div>
												</div>

												<div style={{ width: "100%" }}>
													{payload.multiSession}
													<ProgressBar
														aria-busy={isBusy}
														aria-label="progress bar"
														variant="success"
														now={unit.userProgress?.percentCompleted || 0}
														label={(unit.userProgress?.percentCompleted || 0) + "%"}
													/>
												</div>
												<div>
													<p style={{ textAlign: 'left', fontSize: "12px" }}>
														<b>{unitTotalGrade}:</b>{" "}
														{finalGradesResponse.find(item => item.unitId === unit.id)?.averageQuizGrade ?? 'Grade not found'}
													</p>
												</div>
											</>
										)}
										<div style={{ textAlign: "right" }}>
											{sessionStorage.getItem("ltiLock") == "Y" && (
												<>
													<Button disabled>
														{unit.userProgress?.status === "IN-PROGRESS" &&
															language === "EN"
															? "Continue"
															: unit.userProgress?.status === "Not Started" &&
																language === "EN"
																? "Start"
																: unit.userProgress?.status === "IN-PROGRESS" &&
																	language === "FR"
																	? "Continuer"
																	: unit.userProgress?.status === "Not Started" &&
																		language === "FR"
																		? "Commencer"
																		: ""}
													</Button>
													<br />
													<br />
													<Alert
														key="warning"
														variant="warning"
														style={{ margin: "auto" }}
													>
														<div className="d-flex align-items-center justify-content-center">
															<span className="ml-2 text-center">
																<strong>IMPORTANT</strong> <br />
																Please note that to navigate between chapters, use
																the school portal.
																<br />
																To move between modules, use the table of contents
																within the modules.
															</span>
														</div>
													</Alert>
												</>
											)}

											{sessionStorage.getItem("ltiLock") != "Y" && (
												<>
													<Button
														onClick={async () => {
															await sessionStorage.setItem("activeUnit", unit.id);
															await localStorage.removeItem("TOC");
															await updateUserProgress(
																"UNIT",
																unit.id,
																"IN-PROGRESS"
															);
															window.location.href = "/textbook";
														}}
													>
														{unit.userProgress?.status === "IN-PROGRESS" &&
															language === "EN"
															? "Continue"
															: unit.userProgress?.status === "Not Started" &&
																language === "EN"
																? "Start"
																: unit.userProgress?.status === "IN-PROGRESS" &&
																	language === "FR"
																	? "Continuer"
																	: unit.userProgress?.status === "Not Started" &&
																		language === "FR"
																		? "Commencer"
																		: ""}
													</Button>
												</>
											)}
										</div>
									</div>
								</div>
								<div>
									<hr />
								</div>
							</>
						);
					})}
				</div>

			</>
		)
	}

	const progressUpdate = async () => {
		await setBusy(true);
		const status = await getUserProgressSummary();
		setCourseProgress(status.percentCompleted);
		setCourseComplete(status.lessonsCompleted);
		setCourseTotal(status.totalLessons);
		setUnitGrade(status.unit.grade + "%");
	}

	useEffect(async () => {
		const language = sessionStorage.getItem("lang") || "EN"
		await textbookList();
		await progressUpdate();

		if (language == "FR") {
			setUnitTotalGrade("Note totale de l'unité");
			setLessonsCompleted("leçons terminées");
			setNumLessons("Leçons")
		}

		const textbookprocessor = await textbookList_V2();

		await setTextbookListv2(textbookprocessor);
		await setBusy(false);
	}, []);

	// useEffect(async () => {

	// 	var finalGradesResponse = await calculateGradeByUnit();
	// 	var theUnitGrade = finalGradesResponse.find(item => item.id === unit.id)?.grade ?? 'Grade not found';
	// 	console.log("theUnitGradeUseEffect " + theUnitGrade)
	// 	setFinalGrades(finalGradesResponse)

	// },[finalGrades]);

	return (
		<div>

			<div align="left"> <h1> {payload.props.title}</h1></div><br />

			{textbookListv2}

		</div>
	);
}

export default CurrentCourseList;