// import React from "react";
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginComponent from '../components/login-registration/loginComponent';

function Login() {

  const [userName, setUserName] = useState({});
  const [userId, setUserId] = useState({});

  useEffect(() => {
    setUserDetails();
  }, []);

  const setUserDetails = async () => {
    setUserName('john');
    setUserId('1234');
  }

  return (
    <div >
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <LoginComponent />

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;