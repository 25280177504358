import React, { useState, useEffect } from "react";
import validator from "validator";
import Alert from "react-bootstrap/Alert";
import Placeholder from "react-bootstrap/Placeholder";
import Spinner from "react-bootstrap/Spinner";
import { getSearchUsers } from "../../services/search/getSearchResults";
import IPAddressList from "../ipAddressList";
import { createIpListService } from "../../services/bffServices/createIpList";
import { getAllClasses } from '../../services/getAllClasses';
import { getAllMemberships } from '../../services/getAllMemberships';
import { Form, Button, Table } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal'

function CreateIpListDetailsComponent() {

	//Payload/User Selection
	const [selectedUser, setSelectedUser] = useState("");
	const [classSelected, setClassSelected] = useState("");
	const [membershipSelected, setMembershipSelected] = useState("");
	const [ipAddresses, setIpAddresses] = useState([]);
	const [nameListValue, setListNameValue] = useState("");

	//Dummy Account Modal
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	//General Lists
	const [membershipList, setMembershipList] = useState();
	const [userList, setUserList] = useState([]);
	const [schoolList, setSchoolList] = useState();
	const[fullSchoolList, setFullSchoolList] = useState([])

	const [activeArea, setActiveArea] = useState("Please use Search");
	const [numberOfPages, setNumberOfPages] = useState(1);
	const [updateMsg, setUpdateMsg] = useState();
	const [searchValue, setSearchValue] = useState(null);
	const [nextButton, setNextButton] = useState("Next");
	const [stepupError, setStepupError] = useState("");
	const [userId, setUserId] = useState();

	let pageSize = 10;

	var filterUserList = async () => {
		setUpdateMsg(() => {
			return (
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			)
		});
		var list = await getUserList(searchValue);
		handleShow();
		var numPages = list.length / 10;
		await setNumberOfPages(Math.round(numPages));
		await currentTableData(list, 1);
		setUpdateMsg("");
	}

	var selectDummyAccount = async (studentId) => {
		handleClose();
		setSearchValue("")
		setActiveArea([]);
		setSelectedUser(studentId);
	}

	const currentTableData = async (theOrder, currentPage) => {
		const firstPageIndex = (currentPage - 1) * pageSize;
		const lastPageIndex = firstPageIndex + pageSize;
		setActiveArea(theOrder.slice(firstPageIndex, lastPageIndex));
	};

	var getUserList = async (searchValue) => {
		var userList = await getSearchUsers(searchValue);
		if (userList != null || userList != undefined) {
			await setUserList(userList);
			return userList;
		}
	};

	const getClassInfo = async () => {
		try {
			const schools = await getAllClasses();
			setFullSchoolList(schools);
			setSchoolList(() => {
				return (
					<>
						{schools.map((classSelected) => (
							<>
								<option value={classSelected.schoolId} id="2">
									{classSelected.schoolName}
								</option>
							</>
						))}
					</>
				);
			});
		} catch (error) {
			console.error(error);
		}
	};

	const getMembershipInfo = async () => {
		try {
			const memberships = await getAllMemberships();
			setMembershipList(() => {
				return (
					<>
						{memberships.map((membershipSelected) => (
							<>
								<option value={membershipSelected.membershipKey} id="2">
									{membershipSelected.membershipName}
								</option>
							</>
						))}
					</>
				);
			});
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getClassInfo();
		getMembershipInfo();
	}, []);

	const createIpList = async () => {

		// {
		// 	"ipListId": "ADMIN-001", //Created in login-service
		// "ipListName": "Smart Biology Libraries"
		// 	"schoolId":"29438489d9849",
		// 	"schoolName": "Trial unit 1",
		// 	"ipAddress": ["192.168.1.0","192.168.1.1", "104.159.*"],
		// 	"status": "ACTIVE",
		// 	"dateCreated": "2024-12-01", // in login service
		// 	"dateUpdated": "2024-12-02", //in login srevice
		// 	"lastUpdatedBy": "jmd", //in login service by session ID
		// 	"dummyAccount": {
		// 		"userId":"CX-4208789912",
		// 		"userName":"admin-ip@mailsac.com",
		// 		"email":"admin-ip@mailsac.com"
		// 	},
		// 	"memberships":[{
		// 		"name": "Individual-Access-LIFE-Units-12345",
		// 		"key": "Individual-Access-LIFE-Units-12345"
		// 	}]
		// }



		var payload = {
			eventType: "CREATE",
			ipListName: nameListValue,
			schoolId: classSelected,
			schoolName: fullSchoolList.find(school => school.schoolId === classSelected)?.schoolName || "School not found",
			ipAddresses: ipAddresses,
			dummyAccount: {
				userId: selectedUser.ID,
				userName: selectedUser.Email,
				email: selectedUser.Email,
			},
			memberships: [{
				name: membershipSelected,
				key: membershipSelected
			}]
		};

		console.log("payload" + JSON.stringify(payload));

		var results = await createIpListService(payload);
		// var results = "await createIpListService(payload);"

		var resultsMess = "error101";

		if (results.status != "Success") {
			resultsMess = results.message;
		}

		if (results.status == "Success") {
			resultsMess =
				"Successfully Created Ip List: " + results.name + "/" + results.id;
		}

		if (results.code !== 2) {
			setStepupError(() => {
				return (
					<>
						<Alert key="success" variant="success">
							Ip List has been created
						</Alert>
					</>
				);
			});

			setNextButton("Next");
		}
		if (results.code === 2) {
			setStepupError(() => {
				return (
					<>
						<Alert key="danger" variant="danger">
							Error has occured: {results.message}
							<br />
						</Alert>
					</>
				);
			});
			setNextButton("Next");
		}
		return results;
	};

	const createIpListValidation = async () => {

		// {
		// 	eventType: "CREATE",
		// 	schoolId: classSelected,
		// 	ipAddresses: ipAddresses,
		// 	dummyAccount: {
		// 		userId: selectedUser.ID,
		// 		userName: selectedUser.userName,
		// 		email: selectedUser.Email,
		// 	},
		// 	memberships:[{
		// 		name: membershipSelected,
		// 		key: membershipSelected
		// 	}]
		// }
		if (
			ipAddresses != [] &&
			userId !== "" &&
			classSelected !== "" &&
			selectedUser !== "" &&
			membershipSelected !== ""
		) {
			console.log("fields are valid");
			setStepupError("");
			if (validator.isEmail(selectedUser.Email)) {
				console.log("email is email");
				setNextButton(() => {
					return (
						<>
							<Spinner animation="grow" size="sm" />
						</>
					);
				});
				setStepupError(() => {
					return (
						<>
							<Placeholder xs={6} />
						</>
					);
				});
				console.log("calling createIpList");

				var results = await createIpList();
			} else {
				setStepupError(() => {
					return (
						<>
							<Alert key="danger" variant="danger">
								An incorrect email format has been entered, correct and
								resubmit.
							</Alert>
						</>
					);
				});
			}
		} else {
			setStepupError(() => {
				return (
					<>
						<Alert key="danger" variant="danger">
							Entries missing, please validate all fields have been filled out!
						</Alert>
					</>
				);
			});
		}
	};

	return (
		<div style={{ width: '100%' }}>
			<small>
				<table style={{ width: '80%', margin: '0 auto', border: 'none' }}>
					<tr>
						<td colSpan={2}>
						<div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}> 
  <label htmlFor="studentEmail" style={{ marginRight: '10px', fontSize:"20px", fontWeight: "bold" }}>Name of Ip Range Group:</label> 
  <input
    className="form-control form-control-sm"
    value={nameListValue}
    onChange={event => setListNameValue(event.target.value)}
    type="text"
    autoComplete="text"
    name="studentEmail"
    id="studentEmail"
    // style={{ flex: '1', padding: '5px', border: '1px solid #ccc', borderRadius: '4px' }}
	style={{ 
		flex: '1',
		height: '15px',   
		padding: '3px 8px',
		minHeight: 'calc(1em + .5rem)',
	
	  }} 
  />
</div>
							
<hr />
						</td>
					</tr>
					<tr>
						<td style={{ verticalAlign: 'top' }}>
							<IPAddressList
								onChanged={(updatedList) => {
									setIpAddresses(updatedList);
								}}
							></IPAddressList>
							<hr />
						</td>

						<td style={{ verticalAlign: 'top' }}>
							<div style={{ width: '75%', float: 'right' }}>
								<h5>Select Class & Membership Info</h5>
								<hr/>

								<div style={{ textAlign: "left", marginBottom: '15px', 'font-size': '15px !important' }}>
									<label htmlFor="classSelect" style={{ display: 'block', marginBottom: '5px' }}>Select Class:</label>
									<Form.Select
										aria-label="Select Class"
										id="classSelect"
										onChange={(event) => setClassSelected(event.target.value)}
										style={{
											width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px',
											padding: '3px 8px',
											minHeight: 'calc(1em + .5rem)'
										}}
									>
										<option value="0"></option>
										{schoolList}
									</Form.Select>
								</div>

								<div style={{ textAlign: "left", marginBottom: '15px' }}>
									<label htmlFor="membershipSelect" style={{ display: 'block', marginBottom: '5px' }}>Select Membership:</label>
									<Form.Select
										aria-label="Select Membership"
										id="membershipSelect"
										onChange={(event) => setMembershipSelected(event.target.value)}
										// style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}
										style={{
											width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px',
											padding: '3px 8px',
											minHeight: 'calc(1em + .5rem)'
										}}
									>
										<option value="0"></option>
										{membershipList}
									</Form.Select>
								</div>

								<div style={{ textAlign: "left", marginBottom: '5px' }}>
									<label htmlFor="studentEmail" style={{ display: 'block', marginBottom: '5px' }}>Enter Email address to dummy account:</label>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<input
											className="form-control form-control-sm"
											value={searchValue}
											onChange={event => setSearchValue(event.target.value)}
											type="text"
											autoComplete="text"
											name="studentEmail"
											id="studentEmail"
											style={{ flex: '1', marginRight: '5px', padding: '5px', border: '1px solid #ccc', borderRadius: '4px' }}
										/>
										<Button variant="secondary" size="sm" onClick={() => filterUserList()}>Search for Dummy Account</Button>
									</div>

									{Array.isArray(activeArea) ? (
										<Modal show={show} onHide={handleClose} size="lg">
											<Modal.Header closeButton>
												<Modal.Title>Select Account</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<Table striped bordered hover>
													<thead>
														<tr>
															<th></th>
															<th>ID</th>
															<th>eMail</th>
														</tr>
													</thead>
													{activeArea.map(student => (
														<tbody>
															<tr key={student.ID}>
																<td>
																	<Alert.Link onClick={() => selectDummyAccount(student)}>select</Alert.Link>
																</td>
																<td>{student.ID}</td>
																<td>{student.Email}</td>
															</tr>
														</tbody>
													))}
												</Table>
											</Modal.Body>
											<Modal.Footer>
												<Button variant="secondary" onClick={handleClose}>
													Cancel
												</Button>
											</Modal.Footer>
										</Modal>
									) : (
										<tr></tr>
									)}

									<table width="100%">
										<tr>
											<td align="left" colSpan="2">
												<hr />
												{classSelected != "" &&
													<>
														<strong>School Selected:</strong> {fullSchoolList.find(school => school.schoolId === classSelected)?.schoolName || "School not found"} 
													</>
												}
											</td>
										</tr>
										<tr>
											<td align="left" colSpan="2">
												{membershipSelected != "" &&
													<>
														<strong>Membership Selected:</strong> {membershipSelected}
													</>
												}
											</td>
										</tr>
										<tr>
											<td align="left" colSpan="2">
												{selectedUser != "" &&
													<>
														<strong>Dummy Account Selected:</strong> {selectedUser.Email}
													</>
												}
											</td>
										</tr>
									</table>
									{updateMsg}
								</div>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan="2" align="right">
							<button
								className="btn btn-primary mb-2"
								type="button"
								onClick={createIpListValidation}
							>
								{nextButton}
							</button>
						</td>
					</tr>
					<tr>
						<td colSpan="2" align="center">
							{stepupError}
						</td>
					</tr>
				</table>
			</small>
		</div>
	);
}

export default CreateIpListDetailsComponent;
