import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { resetPassword } from '../../services/bffServices/resetPassword';
import React, { useState } from "react";
import Alert from 'react-bootstrap/Alert'
import { validatePassword } from '../../services/bffServices/validatePassword';
import {
  useParams,
} from "react-router-dom";
import Footer from '../footer';



function ResetPasswordComponent() {
  const queryParameters = new URLSearchParams(window.location.search)
  const [existingPassword, setExistingPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const { id } = useParams();

  const validateNewPassword = async () => {
    if (existingPassword === "" || newPassword === "" || newPasswordRepeat === "") {
      setNewPasswordError(() => {
        return(
        <>
          <Alert key="danger" variant="danger">
            Please enter all fields to reset password
          </Alert>
        </>);
      }
      )
    }

    if (existingPassword !== "" && newPassword !== "" && newPasswordRepeat !== "") {
      if (newPassword != newPasswordRepeat) {
        setNewPasswordError(
          <>
            <Alert key="danger" variant="danger">
              New Passsword Must Match!
            </Alert>
          </>
        )
      } else {
  
        if (!id) {
          var passwordCheck = await validatePassword(existingPassword);
          if (passwordCheck) {
            try {
              resetPassword(newPassword);
              setNewPasswordError(
                <>
                  <Alert key="success" variant="success">
                    Password has been changed
                  </Alert>
                </>
              );
            } catch (err) {
              setNewPasswordError(
                <>
                  <Alert key="danger" variant="danger">
                    Unknown Error Occured...
                  </Alert>
                </>
              )
            }
          } else {
            setNewPasswordError(
              <>
                <Alert key="danger" variant="danger">
                  Existing Passwords don't match!
                </Alert>
              </>
            )
          }
        }
  
        if (id != "" && id != undefined && id != null) {
          // var passwordCheck = await validatePassword(existingPassword);
          // if (passwordCheck) {
          try {
            resetPassword(newPassword, id, true, existingPassword);
            setNewPasswordError(
              <>
                <Alert key="success" variant="success">
                  Password has been changed
                </Alert>
              </>
            );
          } catch (err) {
            setNewPasswordError(
              <>
                <Alert key="danger" variant="danger">
                  Unknown Error Occured...
                </Alert>
              </>
            )
          }
    } else {
      setNewPasswordError(() => {
        return(
        <>
          <Alert key="danger" variant="danger">
            Please enter all fields to reset password
          </Alert>
        </>);
      }
      )
    }

        // } else {
        //   setNewPasswordError(
        //     <>
        //       <Alert key="danger" variant="danger">
        //         Existing Passwords don't match!
        //       </Alert>
        //     </>
        //   )
        // }
      }

    }
  }

  return (
    <>
      
      <Form align="left" style={{ width: "80%", margin: "auto" }}>

        {newPasswordError}<br />
        <Row className="mb-3">
          <Form.Group as={Col} controlId="currentPassword">
            {id != "" && id != undefined && id != null &&
              <>
                <div>
                  <h4>Password Reset</h4>
                </div><br />
                <Form.Label>Email Address*</Form.Label>
              </>
            }

            {!id &&
              <>
                <Form.Label>Existing Password*</Form.Label>
              </>
            }

            <Form.Control autocomplete="current-password" required="true" autoComplete='password' className="placeholder-color" style={{ width: "49%" }} onChange={event => setExistingPassword(event.target.value)} type="password" placeholder="" />
          </Form.Group>

        </Row>
        <Row>
          <Form.Group as={Col} controlId="currentPassword">
            <Form.Label for='password' role='label'>Enter New Password*</Form.Label>
            <Form.Control autocomplete="new-password" id='password' required="true" autoComplete='password' className="placeholder-color" type="password" placeholder="" onChange={event => setNewPassword(event.target.value)} />
          </Form.Group>

          <Form.Group as={Col} controlId="currentPassword">
            <Form.Label for='passwordConfirmation' role='label'>Re-enter New Password*</Form.Label>
            <Form.Control id='passwordConfirmation'  autocomplete="new-password" required="true" autoComplete='password' className="placeholder-color" type="password" placeholder="" onChange={event => setNewPasswordRepeat(event.target.value)} />
          </Form.Group>
        </Row><br />
      
        <Button className="primary-button" onClick={() => validateNewPassword()}>
          Update Password
        </Button>
      </Form>
      <div align="right">
            <small>
                      <p>* indicates a required field</p>
                    </small>
            </div>
      {id != "" && id != undefined && id != null &&
        <>
          <br /><br />
          <Footer />
        </>
      }

    </>
  );
}

export default ResetPasswordComponent;