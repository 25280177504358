

function UpdateIpListDetailsComponent() {
	return (
        <>
         THIS IS THE UPDATE SECTION!
        </>
    )
}

export default UpdateIpListDetailsComponent;
