import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { login, loginByIP } from "../../services/bffServices/login";
import Alert from "react-bootstrap/Alert";
import { IconContext } from "react-icons";
import { MdOutlineErrorOutline } from "react-icons/md";

export default function LoginComponent() {
  const [hasError, setHasError] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [activeErrorId, setErrorId] = useState("");
  const [selectedLibrary, setLibraryValue] = useState("0");

  const handleLibraryChange = (event) => {
    setLibraryValue(event.target.value); 
  };

  const procressLibraryLogin = async (library) => {

   
    if (selectedLibrary != 0) {
      library.preventDefault();
      setHasError("");
      var isValid = await loginByIP(selectedLibrary);

      if(isValid){
        sessionStorage.setItem("existingUser", "Y");
        window.location.href = "/account";
      }

      if(!isValid){
        setHasError(() => {
          setErrorId("LIB002");
          return (
            <>
              <Alert id="errorMessage" key="warning" variant="warning">
              Unable to login from this location, please login from your school.
              </Alert>
            </>
          );
        });
      }
      //call BFF
      
    }

    if (selectedLibrary == 0) {
      setHasError(() => {
        setErrorId("LIB001");
        return (
          <>
            <Alert id="errorMessage" key="warning" variant="warning">
              Please select a library before logging in via library
            </Alert>
          </>
        );
      });
    }

  }

  const processLogin = async (event) => {
    event.preventDefault();
    setHasError("");

    const results = await login(email, password);

    if (results.userDetails.validLogin) {
      if (
        email == "review.adoption@tea.texas.gov" ||
        email == "asepeda@esc1.net" ||
        email == "vilgarcia@esc1.net" ||
        email == "simon.rios@esc2.us" ||
        email == "lsprinkle@esc3.net" ||
        email == "kingram@esc4.net" ||
        email == "elena.vega@esc4.net" ||
        email == "rtritico@esc5.net" ||
        email == "jgraves@esc6.net" ||
        email == "klupton@esc6.net" ||
        email == "bkinsman@esc7.net" ||
        email == "cburris@reg8.net" ||
        email == "diane.balthrop@esc9.net" ||
        email == "lori.aden@region10.org" ||
        email == "fschwope@esc11.net" ||
        email == "mamyett@esc11.net" ||
        email == "kathryn.allen@esc12.net" ||
        email == "amber.laroche@esc13.txed.net" ||
        email == "amber.reinecke@esc13.txed.net" ||
        email == "kdeal@esc14.net" ||
        email == "mcclellan@esc14.net" ||
        email == "mbloomingdale@esc14.net" ||
        email == "traci.terrill@esc15.net" ||
        email == "brenda.foster@esc16.net" ||
        email == "mcastro@esc17.net" ||
        email == "roberto.pena@esc18.net" ||
        email == "djohnson@esc18.net" ||
        email == "mgonzalez@esc19.net" ||
        email == "irma.guerra@esc20.net"
      ) {
        const cookieExists = document.cookie
          .split(";")
          .some((item) => item.trim().startsWith("sessionID="));
        const sessionId = sessionStorage.getItem("sessionID");
        if (cookieExists) {
          const cookieValue = document.cookie.replace(
            /(?:(?:^|.*;\s*)sessionId\s*\=\s*([^;]*).*$)|^.*$/,
            "$1"
          );
          sessionStorage.setItem("sessionID", cookieValue);
        } else {
          document.cookie = `sessionID=${sessionId}; path=/`;
        }
      }

      sessionStorage.setItem("existingUser", "Y");
      window.location.href = "/account";
    }

    if (!results.userDetails.validLogin) {
      if (results.errorId == "M101-LTI") {
        setHasError(() => {
          setErrorId("emailpassword");
          return (
            <>
              <Alert
                id="errorMessage"
                key="warning"
                variant="warning"
                style={{ margin: "auto" }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <IconContext.Provider
                    value={{
                      color: "red",
                      className: "global-class-name",
                      size: "1.5em",
                    }}
                  >
                    <MdOutlineErrorOutline />
                  </IconContext.Provider>
                  <span className="ml-2 text-center">
                    IMPORTANT: Unable to login, please use your school portal to
                    access your content
                  </span>
                </div>
              </Alert>
            </>
          );
        });
      }
      if (results.errorId == "M101") {
        //  setHasError("User Migration in Progress, please reset your password using the email sent to your inbox")

        setHasError(() => {
          setErrorId("password");
          return (
            <>
              <Alert id="errorMessage" key="warning" variant="warning">
                Please reset your password using the email sent to your inbox
              </Alert>
            </>
          );
        });
      }

      if (results.errorId != "M101" && results.errorId != "M101-LTI") {
        setHasError(() => {
          setErrorId("password");
          return (
            <>
              <Alert id="errorMessage" key="danger" variant="danger">
                Incorrect Password, please try again
              </Alert>
            </>
          );
        });
      }

      if (results.errorId == "M101-User") {
        setErrorId("emailpassword");
        setHasError(() => {
          return (
            <>
              <Alert id="errorMessage" key="danger" variant="danger">
                Username/Email does not exist, Validate details entered and
                retry
              </Alert>
            </>
          );
        });
      }
    }
  };

  return (
    <div style={{ padding: "4%", 'padding-bottom':'2px' }}>
      <p style={{ fontSize: "19px" }}>REVOLUTIONIZE YOUR LEARNING TODAY.</p>
      <h1 style={{ fontSize: "32px" }} id="loginHeader">
        Login to your account
      </h1>
      {/* <br></br> */}
      {hasError}
      <table width="100%">
        <tr>
          <td>
            <div style={{color: "#043a83", fontSize: "calc(1.3rem + .6vw)", fontWeight: '500'}} align="left">Account</div>
          </td>
          <td width="10%">

          </td>
          <td width="35%">
          <div style={{color: "#043a83", fontSize: "calc(1.3rem + .6vw)", fontWeight: '500'}} align="left">Library</div>
          </td>
        </tr>
        <tr>
          <td>
            <Form style={{ textAlign: "left" }}>
              <label
                style={{
                  color: activeErrorId.includes("email") ? "red" : null,
                  paddingLeft: "12px",
                  marginBottom: "-12px",
                  fontWeight: "bold",
                }}
                for="emailField"
              >
                <p>Email* or Username*</p>
              </label>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  id="emailField"
                  aria-describedby={
                    activeErrorId.includes("email") ? "errorMessage" : null
                  }
                  style={{
                    fontSize: "19px",
                    color: activeErrorId.includes("email") ? "red" : null,
                    borderColor: activeErrorId.includes("email") ? "red" : null,
                    borderRadius: "20px",
                    width: "100%"
                  }}
                  autoComplete="email"
                  type="email"
                  // placeholder="Enter Email* or Username*"
                  onChange={(event) => {
                    setErrorId("");
                    return setEmail(event.target.value);
                  }}
                  className="placeholder-color"
                  required
                  autocomplete="username"
                />
                <Form.Text
                  className="text-muted"
                  style={{ color: "#595959", paddingLeft: "12px", paddingBottom: "10px" }}
                >
                  <small>We'll never share your email.</small>
                </Form.Text>
              </Form.Group>
              <label
                style={{
                  color: activeErrorId.includes("password") ? "red" : null,
                  paddingLeft: "12px",
                  marginBottom: "-10px",
                  fontWeight: "bold",
                }}
                for="passwordField"
              >
                <p>Password*</p>
              </label>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  id="passwordField"
                  aria-describedby={
                    activeErrorId.includes("password") ? "errorMessage" : null
                  }
                  style={{
                    color: activeErrorId.includes("password") ? "red" : null,
                    borderColor: activeErrorId.includes("password") ? "red" : null,
                    width: "100%"
                  }}
                  autoComplete="password"
                  type="password"
                  // placeholder="Password*"
                  onChange={(event) => {
                    setErrorId("");
                    return setPassword(event.target.value);
                  }}
                  className="placeholder-color"
                  required
                  autocomplete="current-password"
                />
              </Form.Group>
            </Form>
            <a>
              <button
                style={{ marginRight: "20px"}}
                className="primary-button"
                type="submit"
                onClick={processLogin}
              >
                Login
              </button>
            </a>
            <div align="right">
            <small>
                      <p>* indicates a required field</p>
                    </small>
            </div>

          </td>
          <td width="10%">
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '200px', 'text-color': "grey" }}> {/* Adjust height as needed */}
              <div style={{ width: '1px', height: '100%', backgroundColor: 'lightgrey' }}></div>
              <span style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '5px 0',
                color: "grey"
              }}>
                <h3>or</h3>
              </span>
            </div>
          </td>
          <td width="35%" align="center">
            <Form.Select aria-label="Default select example" size="sm" onChange={handleLibraryChange}
              style={{
                color: activeErrorId.includes("LIB001") ? "red" : null,
                borderColor: activeErrorId.includes("LIB001") ? "red" : null,
                width: "100%"
              }} >
              <option value="0">Select your library</option>
              <option value="SEN-001">Seneca Libraries</option>
              <option value="ADMIN-001">Smart Biology Libraries</option>
            </Form.Select> <br />
            <div>
              <a style={{
                backgroundColor: "#f0f0f0",
                color: "#333",
                padding: "10px 15px",
                borderRadius: "5px",
                textDecoration: "none",
                display: "inline-block",
                textAlign: "center",
                width: "fit-content",
                border: "1px solid #ccc",
                cursor: "pointer"
              }}
                onClick={procressLibraryLogin}>
                <small>
                  Library Login
                </small>
              </a>
            </div>
            <hr width="50%" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <a
                href="/#filter"
                className="subtle-link"
                style={{ textAlign: "center" }}
              >
                Not sure where to go?
              </a>
            </div>
          </td>
        </tr>
      </table>


      <div style={{ display: "flex", alignItems: "center" }}>

        {/* 
        <div style={{ display: "flex", flexDirection: "column" }}>
          <a href="/#filter">
            {" "}
            <small style={{ paddingRight: "12px" }}>
              Not sure where to go?
            </small>
          </a>
        </div> */}
        {/* <div style={{ display: "flex", flexDirection: "column" }}>
          <a href="/">
            {" "}
            <small style={{ paddingRight: "12px" }}>
              Are you coming from a bookstore or from Seneca?
            </small>
          </a>
        </div> */}
      </div>
    </div>
  );
}
