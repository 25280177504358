import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from "react-bootstrap/Alert";


function validateIP(ip) {
    const validPatterns = [
        /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d+$/,    // Specific IP (e.g., 10.0.0.1)
        /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\*$/,     // Wildcard last octet (e.g., 10.0.0.*)
        /^\d{1,3}\.\d{1,3}\.\*\.\*$/,          // Wildcard last two octets (e.g., 10.0.*.*)
      ];
  
    return validPatterns.some(pattern => pattern.test(ip));
  }

function IPAddressList({ onChanged }) {
    const [ipList, setIpList] = useState([]);
    const [newIP, setNewIP] = useState('');
    const [validationError, setValidationError] = useState('');
    // const [isValid, setIsValid] = useState(true);

    // const handleInputChange = (e) => {
    //     const inputValue = e.target.value;
    //     setNewIP(inputValue);
    //     setIsValid(validateIP(inputValue)); // Validate on every change
    //   };

    const addIP = () => {
        const isValid = validateIP(newIP)
        if(isValid){
            setValidationError('');
            if (newIP.trim() !== '') {
                const updatedList = [...ipList, newIP.trim()];
                setIpList(updatedList);
                setNewIP('');
                if (onChanged) {
                    onChanged(updatedList);
                }
            }
        }
if(!isValid){


    setValidationError(()=>{
        return (
            <>
        <Alert id="errorMessage" key="danger" variant="danger" style={{ padding: '5px', margin: '10px', fontSize: '12px' }}>
        Incorrect Format, Please Retry
                    </Alert>
                    </>
        )
    })
}


    };

    const removeIP = (index) => {
        const updatedList = ipList.filter((_, i) => i !== index);
        setIpList(updatedList);
        if (onChanged) {

            onChanged(updatedList);
        }
    };

    return (
        <div>
            <h5>Add IP Address Below</h5>
            <hr />
<smal>
<input
  className="form-control" 
  type="text"
  value={newIP}
  onChange={(e) => setNewIP(e.target.value)}
  style={{ 
    height: '15px',   
    padding: '3px 8px',
    minHeight: 'calc(1em + .5rem)',
    marginBottom: '5px'
  }} 
/> 
{validationError}

            <Button style={{margin: "5px"}} variant="secondary" size="sm" onClick={addIP}>Add IP</Button>
            </smal>

            <div style={{'text-align': "left"}}>
                <ul>
                    {ipList.map((ip, index) => (
                        <li key={index}>
                            {ip} <Button
                                variant="link"
                                size="sm"
                                onClick={() => removeIP(index)}><i class="fas fa-trash"></i></Button>
                        </li>
                    ))}
                </ul>
            </div>
            <hr/>
            <div align="left">
            <small>
            <b>Correct Formats: </b>
            <ul>
                <li>
                    192.168.1.0 <br /> <i>(Single IP Address)</i>
                </li>
                <li>
                    192.168.1.* <br /> <i>(Bulk add of Addresses)</i>
                </li>
                <li>
                    192.168.*.* <br /><i>(Bulk add of Addresses)</i>
                </li>
            </ul>
            </small>
            </div>
        </div>
    );
}

export default IPAddressList;
